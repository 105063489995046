import React, { useContext, useRef } from 'react';
import cx from 'classnames';

import withControlled from '../form/with-controlled';

import './style.scss';

const ValueContext = React.createContext();

function Container({ children, name, className, isLoading, onChange, value, disabled, errors, isInvalid }) {
  const handleValueChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <ValueContext.Provider value={{ onValueChange: handleValueChange, value, name }}>
      <div
        className={cx('c-radio__container', {
          [className]: !!className,
          'c-radio--invalid': isInvalid && !disabled,
        })}
      >
        {children}
      </div>
      {errors && <div className="c-radio__error">{errors.message}</div>}
    </ValueContext.Provider>
  );
}

function Option({ className, children, ...props }) {
  const { value, name, onValueChange } = useContext(ValueContext);

  const handleClick = () => {
    onValueChange(props.value);
  };

  return (
    <label className={cx('c-radio', className, { 'c-radio--selected': value === props.value })} onClick={handleClick}>
      <input type="radio" name={name} value={props.value} defaultChecked={value === props.value} onChange={handleClick} {...props} />
      <span className="c-radio__label">{children}</span>
    </label>
  );
}

const Controlled = withControlled(Container);

export default { Container, Option, Controlled };
